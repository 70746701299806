import * as React from "react"
import SignInForm from "../../../components/forms/signInForm"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby-link"

const AccountLoginPage = () => {
    const goHome = () => {
        navigate("/")
    }
    return (
    <div>        
        <div className="block md:hidden">
                <div className="flex relative" style={{height:240 , width:"100%"}}>
                    <StaticImage src="../../../images/account_header.jpg" style={{width:"100%" , height:"100%" , objectFit:"cover",
                     borderBottomLeftRadius:170 , borderBottomRightRadius:170}} alt="login banner"></StaticImage>                                                            
                    <div className="absolute flex p-5 justify-center items-center text-center w-full h-full">
                        <div className="flex flex-col p-2 justify-between items-center h-full">
                            <StaticImage style={{width:60, objectFit:"contain"}} objectFit="contain" src="../../../images/logo_white.png" alt="Shift Logo" ></StaticImage>                  
                            <h1 className="text-white">Bienvenido</h1>  
                        </div>                                                                      
                    </div>                
                </div>
         </div> 
         <div className="p-8">
            <SignInForm onAuthenticationSuccess={goHome}></SignInForm>
        </div>
    </div>

    )
}


export default AccountLoginPage 