import * as React from "react"
import { useEffect, useState } from "react";
import SCButtonGhost from "../buttons/SCGhostButton"
import InputTexfieldRegex from "./InputTexfieldRegex"
import { validateEmail } from "./helper/validationsRegexFuncs.js"
import MailIcon from "@material-ui/icons/Mail"
import { Modal, Input, Checkbox, FormControlLabel } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import TextField from "@material-ui/core/TextField"
import { StaticImage } from "gatsby-plugin-image"
import InputAdornment from "@material-ui/core/InputAdornment"
import CheckIcon from "@material-ui/icons/Check"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import IconButton from "@material-ui/core/IconButton"
import LockIcon from "@material-ui/icons/Lock"
import SCButton from "../buttons/SCButton"
import firebase from "firebase";
import { createUser } from "../../api/content"
import { Link } from "gatsby"

const SignInForm = ({
  onSignUpRequest,
  onResetPasswordRequest,
  onAuthenticationSuccess,
  onDismiss,
}) => {

  const [showPassword, setShowPassword] = React.useState(false)
  // const [email, setEmail] = React.useState()
  const [password, setPassword] = React.useState()
  const [error, setError] = React.useState()
  const [userLogin, setUserLogin] = React.useState({
    email: "",
  })
  const [os, setOs] = useState('');

  useEffect(() => {
    const os = getOS();
    setOs(os);
  }, []);

  const updateProperty = (property, value) => {
    setUserLogin({ ...userLogin, [property]: value })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const googleSignIn = () => {
    let provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        // The signed-in user info.
        var user = result.user
        // ...

        if (result.additionalUserInfo.isNewUser) {
          createUser({
            email: user.email,
            firebaseId: user.uid,
            firstName: user.displayName,
            avatar: user.photoURL,
            password:
              result.additionalUserInfo?.providerId ?? "xdESslkdskmmdsk",
          })
            .then()
            .catch(error => {
              console.log(error)
            })
        }
        if (onAuthenticationSuccess) onAuthenticationSuccess()
      })
      .catch(error => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // The email of the user's account used.
        var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential

        setError(authMessageError(error.code))
      })
  }

  const facebookSignIn = () => {
    var provider = new firebase.auth.FacebookAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential

        // The signed-in user info.
        var user = result.user

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken
        if (result.additionalUserInfo.isNewUser) {
          createUser({
            email: user.email,
            firebaseId: user.uid,
            firstName: user.displayName,
            avatar: user.photoURL,
            password:
              result.additionalUserInfo?.providerId ?? "xdESslkdskmmdsk",
          })
            .then()
            .catch(error => {
              console.log(error)
            })
        }

        if (onAuthenticationSuccess) onAuthenticationSuccess()

        // ...
      })
      .catch(error => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // The email of the user's account used.
        var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential
        setError(authMessageError(error.code))
        // ...
      })
  }

  const appleSignIn = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com")
    provider.setCustomParameters({
      // Localize the Apple authentication screen in Spanish MX.
      locale: "es_MX",
    })

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential

        // The signed-in user info.
        var user = result.user

        // You can also get the Apple OAuth Access and ID Tokens.
        var accessToken = credential.accessToken
        var idToken = credential.idToken
        if (result.additionalUserInfo.isNewUser) {
          createUser({
            email: user.email,
            firebaseId: user.uid,
            firstName: user.displayName,
            avatar: user.photoURL,
            password:
              result.additionalUserInfo?.providerId ?? "xdESslkdskmmdsk",
          })
            .then()
            .catch(error => {
              console.log(error)
            })
        }

        if (onAuthenticationSuccess) onAuthenticationSuccess()

        // ...
      })
      .catch(error => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // The email of the user's account used.
        var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential
        setError(authMessageError(error.code))
        // ...
      })
  }

  const signIn = () => {
    if (validate()) {
      firebase
        .auth()
        .signInWithEmailAndPassword(userLogin.email, password)
        .then(userCredentials => {
          if (userCredentials.user) {
            setTimerLogin();
            if (onAuthenticationSuccess) onAuthenticationSuccess()
            eventTag('Email')
          } else {
            setError("Usuario no autenticado")
          }
        })
        .catch(error => {
          setError(authMessageError(error.code))
        })
    }
  }

  const setTimerLogin = () => {
    localStorage.setItem('B2B_LOGIN_TIME', Date.now());
  }

  const authMessageError = code => {
    // console.log(code)
    let message = ""
    switch (code) {
      case "auth/user-not-found":
        message = "Usuario no encontrado"
        break
      case "auth/popup-closed-by-user":
        message = "El usuario cancelo el inicio de sesión"
        break
      case "auth/invalid-email":
        message = "El correo electrónico no tiene un formato correcto"
        break
      case "auth/wrong-password":
        message = "La contraseña que ingresaste es incorrecta."
        break
      case "auth/too-many-requests":
        message = "Demasiados intentos. ¿Olvidó su contraseña?"
        break
      default:
        message = code
    }
    return message
  }

  const validate = () => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!userLogin.email) {
      setError("Ingresa tu correo electrónico")
      return false
    }
    if (!password) {
      setError("Ingresa tu contraseña")
      return false
    }
    if (!emailRegex.test(userLogin.email)) {
      setError("Formato de correo electrónico incorrecto")
      return false
    }
    return true
  }

  const getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE', 'Win'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

  const eventTag = (TypeSession) =>{
    if(window.gtag){
      window.gtag('event', `InicioSesion-${userLogin.email}`, {
          'event_category': 'custom',
          'event_label': 'registro_form',
          'typeSeccion':TypeSession,
          'value': 1
        });
    }
  }
  return (
    <main>
      <h3 className="text-sc-black hidden md:block" style={{ fontSize: 32 }}>
        Iniciar sesión
      </h3>
      <div className="w-full mb-8">
        <InputTexfieldRegex
          onChange={userLogin.email}
          setOnChange={updateProperty}
          property={"email"}
          validateFunc={validateEmail}
          placeHolder={"Email"}
          type="email"
        >
          <MailIcon style={{ color: "#6B6B6B" }} />
        </InputTexfieldRegex>
      </div>
      <div className="w-full  mb-8">
        <Input
          className="w-full"
          id="standard-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          placeholder="Contraseña"
          onChange={e => {
            setPassword(e.target.value)
          }}
          startAdornment={
            <InputAdornment position="start">
              <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>

      <div className="text-center">
        <p className="hidden md:block">
          ¿Olvidaste tu contraseña? Recuperala{" "}
          <span
            className="text-sc-titles"
            onClick={onResetPasswordRequest}
            style={{ cursor: "pointer" }}
          >
            <b>aquí</b>
          </span>
        </p>
        <p className="block md:hidden w-full">
          ¿Olvidaste tu contraseña? Recuperala
          <Link
            className="inline-block md:hidden mb-6 mt-6 text-sc-titles"
            to="/pwa/account/reset"
            style={{ cursor: "pointer" }}
          >
            <b style={{ marginLeft: "6px" }}> aquí</b>
          </Link>
        </p>
      </div>
      {error ? (
        <div className="text-red-600 text-center mb-4">{error}</div>
      ) : null}
      <div className="mb-4 flex justify-center">
        <div style={{ width: 250 }}>
          <SCButton onClick={signIn}>
            <b>Inicia sesión</b>
          </SCButton>
        </div>
      </div>

      <div className="justify-center flex flex-row" style={{ display: "none" }}>
        <hr className="w-24 mt-3 mr-6"></hr>
        <p className="mr-6">ó</p>
        <hr className="w-24 mt-3"></hr>
      </div>
      <div className="">
        <div className="mb-4">
          <SCButtonGhost onClick={googleSignIn}>
            <div className="flex justify-between items-center h-full">
              <div className="flex-inital justify-end ml-4 mt-1">
                <StaticImage
                  src="../../images/google_icon.png"
                  style={{
                    width: "22px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                ></StaticImage>
              </div>
              <div className="flex-1">Inicia sesión con Google</div>
            </div>
          </SCButtonGhost>
        </div>
        {/* <div className="mb-4">
          <SCButtonGhost onClick={facebookSignIn}>
            <div className="flex justify-between items-center h-full">
              <div className="flex-inital justify-end ml-4 mt-1">
                <StaticImage
                  src="../../images/facebook_icon.png"
                  style={{
                    width: "22px",
                    height: "20px",
                    objectFit: "contain",
                  }}
                ></StaticImage>
              </div>
              <div className="flex-1">Inicia sesión con Facebook</div>
            </div>
          </SCButtonGhost>
        </div> */}
        {
          os !== 'Windows' &&
          <div className="mb-4">
            <SCButtonGhost onClick={appleSignIn}>
              <div className="flex justify-between items-center h-full">
                <div className="flex-inital justify-end ml-4 mt-1">
                  <StaticImage
                    src="../../images/apple_icon.png"
                    style={{
                      width: "20px",
                      height: "20px",
                      objectFit: "contain",
                    }}
                  ></StaticImage>
                </div>
                <div className="flex-1">Inicia sesión con Apple</div>
              </div>
            </SCButtonGhost>
          </div>
        }
      </div>
      <div style={{ display: "none" }} className="text-center">
        <p
          className="hidden md:block"
          onClick={onDismiss}
          style={{ cursor: "pointer", fontSize: 18 }}
        >
          <b>Iniciar sesión como invitado</b>
        </p>
        <Link
          className="block md:hidden mb-6 mt-6"
          to="/"
          style={{ cursor: "pointer", fontSize: 18 }}
        >
          <b>Iniciar sesión como invitado</b>
        </Link>
      </div>

      <div className="text-center">
        <p className="hidden md:block">
          ¿No tienes una cuenta?
          <span
            className="text-sc-titles"
            onClick={onSignUpRequest}
            style={{ cursor: "pointer" }}
          >
            <b> Regístrate</b>
          </span>
        </p>
        <p className="block md:hidden">
          ¿No tienes una cuenta?
          <Link
            className="inline-block md:hidden mb-6 mt-6 text-sc-titles"
            to="/pwa/account/register"
            style={{ cursor: "pointer" }}
          >
            <b> Regístrate</b>
          </Link>
        </p>
      </div>
    </main>
  )
}

export default SignInForm;
